exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-moje-projekty-js": () => import("./../../../src/pages/moje-projekty.js" /* webpackChunkName: "component---src-pages-moje-projekty-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-praca-js": () => import("./../../../src/pages/praca.js" /* webpackChunkName: "component---src-pages-praca-js" */),
  "component---src-pages-skolenia-js": () => import("./../../../src/pages/skolenia.js" /* webpackChunkName: "component---src-pages-skolenia-js" */),
  "component---src-pages-znalosti-js": () => import("./../../../src/pages/znalosti.js" /* webpackChunkName: "component---src-pages-znalosti-js" */)
}

